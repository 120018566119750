@import '~bootstrap';
@import "toastr";
@import "font-awesome";
@import "~aos/src/sass/aos";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.carousel-caption{
    top: 0px;
    width: 40%;
    padding: 15% 0px;
    a{
        color: #FFFFFF;
    }
    p{
        font-size: 23px;
        line-height: 29px;
        @media (max-width: 575px) {
            font-size: 15px;
            line-height: 18px;
            max-height: 70px;
            overflow: hidden;
            display: block;
        }
    }
    @media (max-width: 767px) {
        padding: 7% 0px;
    }
    @media (max-width: 575px) {
        width: 45%;
        padding: 0px;
    }
    h1{
        color: #FFFFFF;
        @media (max-width: 991px) {
            font-size: 40px;
        }
        @media (max-width: 767px) {
            font-size: 35px;
        }
        @media (max-width: 575px) {
            font-size: 22px;
        }
    }
}

@font-face {
    font-family: 'Goldplay';
    src: local('Goldplay Regular'), local('Goldplay-Regular'),
        url('../fonts/Goldplay-Regular.woff2') format('woff2'),
        url('../fonts/Goldplay-Regular.woff') format('woff'),
        url('../fonts/Goldplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}


$azul_claro: #00bbb6;
$azul_escuro: #29244e;
$rosa: #ff2b5c;
$amarelo: #ffaf3b;


ul{
    .pagination{
        justify-content: flex-end!important;
    }
}

.text-azul-claro {
    color: $azul_claro !important;
}
.text-azul-escuro {
    color: $azul_escuro !important;
}
.text-rosa {
    color: $rosa !important;
}
.text-amarelo {
    color: $amarelo !important;
}
.navbar-toggler-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28 255, 255, 255, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.form-label{
    font-weight: bold;
}

header{
    nav{
        a{
            &.nav-link{
                font-size: 19px;
                line-height: 24px;
                color: #ffffff;
                &.active{
                    color: #FFFFFF !important;
                    font-weight: bold;
                }
                @media (max-width: 1399px) {
                    font-size: 16px;
                }
            }
        }
        &.scrolled{
            background-color: $azul_claro;
        }
        @media (max-width: 767px) {
            background-color: $azul_claro;
            position: relative !important;
            .navbar-brand{
                display: contents;
                img{
                    max-width: 60%;
                }
            }
        }
        @media (max-width: 767px) {
            .navbar-nav{
                text-align: center;
            }
        }
    }
    &.interna{
        nav{
            a{
                &.nav-link{
                    color: #2c294e;
                    &.active{
                        color: #2c294e !important;
                    }
                }
            }
            &.scrolled{
                background-color: #f7f8f8;
            }

            @media (max-width: 767px) {
                background-color: #f7f8f8;
            }
        }
        .btn-light-white{
            color: $rosa;
            border: 1px solid $rosa;
        }
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28 33, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
        }
    }
}

.btn-light-white{
    color: #FFFFFF;
    border:1px solid #FFFFFF;
    border-radius: 15px;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    text-align: center;
    &:hover{
        color: #FFFFFF;
        border:1px solid #FFFFFF;
        opacity: 0.7;
    }
    @media (max-width: 1399px) {
        font-size: 16px;
    }
}

.btn-light-rosa{
    color: $rosa;
    border: 1px solid $rosa;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 15px;
    padding: 14px 50px;
    &:hover{
        color: #FFFFFF;
        background-color: $rosa;
    }
}

.btn-rosa{
    padding: 10px 30px;
    color: #FFFFFF;
    border:1px solid $rosa;
    border-radius: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    background-color: $rosa;
    box-shadow: 5px 5px 5px #CCCCCC;
    &:hover{
        color: $rosa;
        border:1px solid #FFFFFF;
    }
}

.btn-amarelo{
    padding: 14px 50px;
    color: #FFFFFF;
    border:1px solid $amarelo;
    border-radius: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    background-color: $amarelo;
    box-shadow: 5px 5px 5px #CCCCCC;
    &:hover{
        color: $amarelo;
        border:1px solid #FFFFFF;
    }
}

.btn-roxo{
    padding: 14px 50px;
    color: #FFFFFF;
    border:1px solid $azul_escuro;
    border-radius: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    background-color: $azul_escuro;
    box-shadow: 5px 5px 5px #CCCCCC;
    &:hover{
        color: $azul_escuro;
        border:1px solid #FFFFFF;
    }
}

.btn-roxo-claro{
    padding: 14px 50px;
    color: #FFFFFF;
    border:1px solid #696781;
    border-radius: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    background-color: #696781;
    box-shadow: 5px 5px 5px #1e1a3a;
    &:hover{
        color: #FFFFFF;
        border:1px solid #696781;
        opacity: 0.7;
    }
}

.btn-rosa-claro{
    padding: 14px 50px;
    color: #FFFFFF;
    border:1px solid #f26b8b;
    border-radius: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    background-color: #f26b8b;
    &:hover{
        color: #f26b8b;
        border:1px solid #FFFFFF;
        background-color: #FFFFFF;
    }
}

a{
    text-decoration: none;
}

hr{
    border-top: 1px solid #ececf1 !important;
    opacity: 1;
}

img{
    max-width: 100%;
}

h1, h2, h3, h4, h5, h6{
    color: $azul_escuro;
}

h1{
    font-size: 54px;
}

.fs-14{
    font-size: 14px;
}
.fs-16{
    font-size: 16px;
}
.fs-18{
    font-size: 18px;
}
.fs-19{
    font-size: 19px;
}
.fs-20{
    font-size: 20px;
}
.fs-22{
    font-size: 22px;
}
.fs-24{
    font-size: 24px;
}
.fs-26{
    font-size: 26px;
}
.fs-30{
    font-size: 30px;
}
.fs-40{
    font-size: 40px;
}
.fs-50{
    font-size: 50px;
}
.fs-58{
    font-size: 58px;
}
.fs-60{
    font-size: 60px;
}
.fs-62{
    font-size: 62px;
}
.fs-70{
    font-size: 70px;
}

body{
    font-family: 'Goldplay', sans-serif;
    background-color: #f7f8f8;
    background-image: url(/assets/images/bg-main.webp);
    background-position: top right;
}

.faca-parte{
    margin-top: 30px;
    .faca-parte-box{
        text-align: center;
        background-color: #FFFFFF;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 5px 5px 5px #CCCCCC;
        margin-top: 20px;
        figure{
            min-height: 250px;
            text-align: center;
        }
        h2{
            font-size: 24px;
            line-height: 26px;
            color: #212121;
            font-weight: bold;
            padding-top: 20px;
        }
        p{
            font-size: 16px;
            line-height: 28px;
            color: #212121;
        }
    }
    .faca-parte-empresas{
        background-image: url(/assets/images/bg-rosa.webp);
        background-size: 100% 40%;
        background-repeat: no-repeat;
        padding: 30px 15px;
    }
    .faca-parte-ongs{
        background-image: url(/assets/images/bg-amarelo.webp);
        background-size: 100% 40%;
        background-repeat: no-repeat;
        padding: 30px 15px;
    }
    .faca-parte-fisica{
        background-image: url(/assets/images/bg-cinza.webp);
        background-size: 100% 40%;
        background-repeat: no-repeat;
        padding: 30px 15px;
    }
}


.convite{
    margin-top: 30px;
    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 122px;
        background-image: url('/assets/images/rosa-bg-top.webp');
        background-position: center;
    }
    &::after{
        content: '';
        display: block;
        width: 100%;
        height: 82px;
        background-image: url('/assets/images/rosa-bg-bottom.webp');
        background-position: center;
    }
    .convite-bg{
        background-color: #ed2f5b;
        color: #FFFFFF;
        h2{
            font-size: 68px;
            line-height: 71px;
            color: #FFFFFF;
        }
        p{
            font-size: 23px;
            line-height: 29px;
        }
    }
}

section.numeros{
    text-align: center;
    margin-top: 30px;
    h2{
        font-size: 68px;
        line-height: 71px;
    }
    h3{
        font-size: 67px;
        line-height: 73px;
        color: #212121;
        font-weight: bold;
    }
    .numeros-box{
        display: inline-block;
    }
    p{
        font-size: 16px;
        line-height: 28px;
        color: #212121;
    }
}

.quem-somos{
    color: #FFFFFF;
    margin-top: 30px;
    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 81px;
        background-image: url('/assets/images/bg-quem-somos.webp');
        background-position: center;
    }
    .quem-somos-bg{
        padding: 20px 0px 0px 0px;
        h2{
            font-size: 64px;
            line-height: 67px;
            color: #ffffff;
        }
        p{
            font-size: 23px;
            line-height: 29px;
        }
        background-color: #2d294f;
        .quem-somos-image{
            background-image: url(/assets/images/sobre-mao-amiga.webp);
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: contain;
        }
    }
}

.newsletter{
    margin-top: 30px;
    color: #ffffff;
    .newsletter-top{
        position: relative;
        height: 90px;
        overflow: hidden;
        &::before{
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 180px;
            background-color: #04bab5;
            border-radius: 50%;
        }
    }
    .newsletter-bg{
        background-color: #04bab5;
        h2{
            font-size: 68px;
            line-height: 71px;
            color: #ffffff;
        }
        p{
            font-size: 31px;
            line-height: 53px;
        }
    }
    input{
        height: 67px;
        border-radius: 9px;
        border: 1px solid #FFFFFF;
        min-width: 280px;
        margin: 5px;
        padding-left: 10px;
    }
    .newsletter_button{
        font-size: 19px;
        line-height: 32px;
        color: #ffffff;
        font-weight: bold;
        background-color: #2c294e;
        text-align: center;
        padding: 15px 35px;
        border-radius: 9px;
        border: 1px solid #2c294e;
        margin: 5px;
    }
    
}

.footer{
    background-color: $azul_escuro;
    padding: 20px 0px 10px 0px;
    text-align: center;
    font-size: 21px;
    line-height: 37px;
    color: #eff1ff;
}

.page-link{
    color: $rosa;
    &.active{
        background-color: $rosa;
        border-color: $rosa;
    }
}

.active{
    &>.page-link{
        background-color: $rosa;
        border-color: $rosa;
    }
}

.centro-acolhimento{
    padding-top: 120px;
    .card{
        border-radius: 20px;
        border-color: #FFF;
        box-shadow: 5px 5px 5px #CCCCCC;
        .card-body{
            h2{
                font-size: 20px;
                line-height: 22px;
                color: #212121;
                font-weight: bold;
            }
        }
    }
    h1{
        color: $rosa;
        font-size: 68px;
        line-height: 71px;
        text-align: center;
        @media (max-width: 991px) {
            font-size: 40px;
            line-height: 44px;
        }
    }
    .center_filter{
        padding: 30px;
    }
    .center_type{
        background-color: #ffeaef;
        padding: 30px;
        label{
            font-weight: 100 !important;
        }
        .form-check-input{
            &:checked{
                background-color: $rosa;
                border-color: $rosa;
            }
        }
    }
    .centro-acolhimento-menu{
        border-radius: 50px;
        background-color: #ffffff;
        overflow: hidden;
        h3{
            text-align: center;
            font-size: 24px;
            line-height: 26px;
            color: #212121;
            font-weight: bold;
        }
        label{
            font-size: 20px;
            line-height: 22px;
            color: #212121;
            font-weight: bold;
        }
        select{
            &.form-control{
                padding: 0.8rem 0.75rem;
                border-radius: 20px;
            }
        }
    }
}

.faq{
    color: #FFFFFF;
    margin-top: 30px;
    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 81px;
        background-image: url('/assets/images/bg-quem-somos.webp');
        background-position: center;
    }
    &::after{
        content: '';
        display: block;
        width: 100%;
        height: 118px;
        background-image: url('/assets/images/faq-bg-bottom.webp');
        background-position: center;
    }
    .faq-bg{
        padding: 20px 0px 0px 0px;
        background-color: #2d294f;
        h2{
            font-size: 64px;
            line-height: 67px;
            color: #ffffff;
            padding: 20px 0px;
        }
        p{
            font-size: 23px;
            line-height: 29px;
        }
        .faq-image{
            background-image: url(/assets/images/faq-mao-amiga.webp);
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: contain;
        }
    }

    .accordion-item{
        margin-bottom: 20px;
        border:1px solid #2d294f;
        .accordion-header{
            padding: 0px;
            .accordion-button{
                height: 70px;
                font-size: 1.5rem;
                font-weight: bold;
            }
        }
    }

    .accordion-button:not(.collapsed){
        background-color: $azul_claro;
    }
    .accordion-body{
        background-color: #2d294f;
        color: #FFFFFF;
        a{
            color: #FFFFFF;
        }
    }
}

.ongs-box{
    .content_ca{
        min-height: 130px;
    }
}

.body_ca{
    img{
        max-width: 150px;
        float: left;
        margin: 0px 10px 10px 0px;
    }
}